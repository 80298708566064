import Image from 'next/image';
import React from 'react';

import { cx } from '@emotion/css';

import styles from './Error.module.scss';

export const ErrorConnector = () => {
  return (
    <div className={styles.body}>
      <div className={cx(styles.sec, styles.root, styles.root_home)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 100,
            marginBottom: 100,
          }}>
          <div style={{ marginBottom: 48, fontSize: 28 }}>
            Oops could not find the Page. Go back to home page and try again.
          </div>
          <Image
            src="/images/404.svg"
            width="600"
            height="400"
            alt="Page not found"
          />
        </div>
      </div>
    </div>
  );
};
