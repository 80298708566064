import { NextSeo } from 'next-seo';
import Head from 'next/head';

import { ErrorConnector } from '@modules/error/ErrorConnector';

const TITLE = 'Qeeper - Page not found';
const DESC =
  'On-demand property maintenance across London, Edinburgh and Brighton';
const URL = 'https://www.qeeper.co.uk/404';

const SEO_KEYWORDS =
  'On-demand property maintenance across London, Edinburgh and Brighton';

function Custom404() {
  return (
    <>
      <Head>
        <meta name="keywords" content={SEO_KEYWORDS} />
        <link rel="canonical" key="canonical" href={URL} />
        <link rel="alternate" hrefLang="en" href={URL} />
      </Head>
      <NextSeo
        title={TITLE}
        description={DESC}
        canonical={URL}
        openGraph={{
          url: URL,
          title: TITLE,
          description: DESC,
        }}
      />
      <ErrorConnector />
    </>
  );
}

export default Custom404;
